import React, { useCallback } from 'react'

import { FormFieldProps } from '@/components/form-template/inputs/dynamic-input'
import { PublicAssetUploadSimple } from '@/components/shared/asset-upload/public-asset-upload-simple'
import { UploaderAsset } from '@/lib/hooks/use-asset-uploader-simple'

export const InputFile = ({ field, form }: FormFieldProps) => {
  const submitHandler = useCallback(
    async (assets: UploaderAsset[] | null) => {
      const assetIds = (assets || []).map((asset) => asset.id).join(',')
      form.setValue(field?.id!, assetIds, { shouldValidate: true })
    },
    [field?.id, form],
  )

  return (
    <div className="mt-6">
      <PublicAssetUploadSimple
        size={100}
        bucket="form-response-uploads"
        onUploadComplete={(assets) => {
          submitHandler(assets || [])
        }}
      />
    </div>
  )
}
