import { useTranslation } from 'react-i18next'

import { Input } from '@/components/ui/input'

import { FormFieldProps } from './dynamic-input'

export const InputNumber = ({
  field,
  form,
  inputClassName,
}: FormFieldProps) => {
  const { t } = useTranslation()
  const content = field?.content

  return (
    <Input
      {...form.register(`${field?.id}`, {
        required: content?.required ? 'Field is required' : false,
      })}
      type="number"
      placeholder={t('enter_a_number')}
      className={inputClassName}
    />
  )
}
