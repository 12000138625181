import { Input } from '@/components/ui/input'

import { FormFieldProps } from './dynamic-input'

export const InputDate = ({ field, form, inputClassName }: FormFieldProps) => {
  const content = field?.content

  return (
    <Input
      {...form.register(`${field?.id}`, {
        required: content?.required ? 'Field is required' : false,
      })}
      type="date"
      placeholder="dd/mm/yyyy"
      className={inputClassName}
    />
  )
}
