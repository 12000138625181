import { FormFieldProps } from './dynamic-input'

export const InputCheckbox = ({ field, form }: FormFieldProps) => {
  const content = field?.content

  return (
    <>
      {(content?.options || []).map((row) => (
        <label key={row.id} className="block">
          <input
            type="checkbox"
            value={row.label}
            {...form.register(`${field.id}`, {
              validate: () => {
                if (content?.required) {
                  const checkboxGroup = form.getValues()[field?.id!]
                  return (
                    checkboxGroup?.length > 0 ||
                    'At least one option must be selected'
                  )
                }
                return true
              },
            })}
            className="mr-2"
          />
          {row.label}
        </label>
      ))}
    </>
  )
}
