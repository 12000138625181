import { CrossSmallIcon, GlowingDotIcon } from '@fingertip/icons'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AssetUploadItemPreview } from '@/components/shared/asset-upload/asset-upload-item-preview'
import { ButtonDiv } from '@/components/ui/button'
import { labelVariants } from '@/components/ui/label'
import { Progress } from '@/components/ui/progress'
import { UploaderAsset } from '@/lib/hooks/use-asset-uploader'
import { cn } from '@/lib/utils'
import { fileSrc } from '@/lib/utils/image-loader'

type Props = {
  asset: UploaderAsset
  removeAssetUpload: (assetUpload: UploaderAsset) => void
  size: number
  className?: string
  hidePreview?: boolean
  hideDetails?: boolean
  disabled?: boolean
}

export const AssetUploadItem = ({
  asset,
  className,
  hidePreview,
  size,
  removeAssetUpload,
  hideDetails,
  disabled,
}: Props) => {
  const { t } = useTranslation()

  const handleDownload = useCallback(async () => {
    let blob = await fetch(
      fileSrc({
        bucket: asset?.bucket,
        src: asset?.src,
      }),
    ).then((r) => r.blob())
    const href = URL.createObjectURL(blob)

    // Create a link element to trigger the download
    const link = document.createElement('a')
    link.href = href
    link.download = `${asset?.src}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [asset?.bucket, asset?.src])

  return (
    <div key={asset.id} className="relative mb-4 flex flex-col">
      <div className="flex items-center">
        <div className="relative">
          <AssetUploadItemPreview
            loading={asset.status !== 'Completed'}
            className={className}
            asset={asset}
            hidePreview={hidePreview}
            size={size}
          />
          <ButtonDiv
            disabled={disabled}
            variant="secondary"
            size="xs"
            className="bg-background absolute -right-1.5 -top-1.5 z-10 size-[24px] rounded-full! p-0"
            type="button"
            onClick={() => removeAssetUpload(asset)}
            title={t('remove_0')}
          >
            <CrossSmallIcon className="size-4" />
          </ButtonDiv>
        </div>
        {!hideDetails && (
          <div className="ml-4 flex-1 flex-col space-y-2">
            <div>
              <div className="line-clamp-1" title={asset.fileName}>
                <button
                  disabled={disabled}
                  onClick={handleDownload}
                  className={cn(
                    labelVariants(),
                    'text-left cursor-pointer hover:underline block',
                  )}
                >
                  {asset.fileName}
                </button>
              </div>
              {!!asset.new && (
                <div
                  className={cn(
                    'flex flex-row items-center gap-2 leading-[1.5]',
                  )}
                >
                  <GlowingDotIcon
                    width={12}
                    height={12}
                    className={cn({
                      'text-success': asset.status === 'Completed',
                      'text-destructive': asset.status === 'Failed',
                      'text-warning':
                        asset.status === 'Uploading' ||
                        asset.status === 'Initial',
                    })}
                  />
                  <div
                    className={cn('text-sm', {
                      'text-success-foreground': asset.status === 'Completed',
                      'text-destructive-foreground': asset.status === 'Failed',
                      'text-warning-foreground':
                        asset.status === 'Uploading' ||
                        asset.status === 'Initial',
                    })}
                  >
                    {asset.status === 'Failed'
                      ? 'Failed'
                      : asset.status === 'Completed'
                        ? 'Uploaded successfully'
                        : 'Uploading...'}
                  </div>
                </div>
              )}

              {asset.status === 'Uploading' && (asset?.progress || 0) > 0 && (
                <div className="mt-2 w-full">
                  <Progress
                    value={Math.round(asset?.progress || 0)}
                    className="h-2 w-full"
                  >
                    {asset.progress}%
                  </Progress>

                  <div className="mt-1 text-xs text-muted-foreground">
                    {asset.progress?.toFixed(0)}%
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
