import { Input } from '@/components/ui/input'

import { FormFieldProps } from './dynamic-input'

export const InputTime = ({ field, form, inputClassName }: FormFieldProps) => {
  const content = field?.content

  return (
    <Input
      {...form.register(`${field?.id}`, {
        required: content?.required ? 'Field is required' : false,
      })}
      type="time"
      placeholder="h:mm am/pm"
      className={inputClassName}
    />
  )
}
