import { useTranslation } from 'react-i18next'

import { Textarea } from '@/components/ui/textarea'

import { FormFieldProps } from './dynamic-input'

export const InputTextarea = ({
  field,
  form,
  inputClassName,
}: FormFieldProps) => {
  const { t } = useTranslation()
  const content = field?.content

  return (
    <Textarea
      {...form.register(`${field?.id}`, {
        required: content?.required ? 'Field is required' : false,
      })}
      placeholder={t('enter_your_answer')}
      className={inputClassName}
      minRows={2}
    />
  )
}
