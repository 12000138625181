import { useTranslation } from 'react-i18next'

import { cn } from '@/lib/utils/cn'

import { FormFieldProps } from './dynamic-input'

export const InputSelect = ({
  field,
  form,
  inputClassName,
}: FormFieldProps) => {
  const { t } = useTranslation()

  const content = field?.content

  return (
    <select
      {...form.register(`${field?.id}`, {
        required: content?.required ? 'Field is required' : false,
      })}
      defaultValue=""
      required={content?.required}
      className={cn(
        'input flex h-[52px] w-full rounded-2xl border border-input bg-card px-4 py-[14px] font-sans font-normal text-base leading-[22px] text-foreground ring-offset-background placeholder:text-placeholder-foreground focus:outline-hidden focus:border-ring disabled:cursor-not-allowed disabled:opacity-50',
        inputClassName,
      )}
    >
      <option value="" disabled={true}>
        {t('please_select_an_opt')}
      </option>
      {(content?.options || []).map((option) => (
        <option value={option.label} key={option.id}>
          {option.label}
        </option>
      ))}
    </select>
  )
}
