import type { PartialMessage } from '@bufbuild/protobuf'
import type { FormField } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/form_field_pb'

import { cn } from '@/lib/utils'

type Props = {
  field: PartialMessage<FormField>
  className?: string
}

export const BlockHeading = ({ field, className }: Props) => {
  const content = field?.content

  return (
    <div className={cn('mb-4', className)}>
      {content?.label && (
        <h2 className="text-2xl font-bold">{content.label}</h2>
      )}
      {content?.instruction && (
        <div
          className="mb-0 mt-2"
          dangerouslySetInnerHTML={{
            __html: content.instruction.replace(/\n/g, '<br />'),
          }}
        />
      )}
    </div>
  )
}
