import { PartialMessage } from '@bufbuild/protobuf'
import { useMutation } from '@connectrpc/connect-query'
import { Asset } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/asset_pb'
import {
  createPublicAsset,
  deletePublicAsset,
} from '@fingertip/creator-proto/gen/fingertip/creator/asset/v1/asset-AssetService_connectquery'
import { ArrowOutOfBoxIcon } from '@fingertip/icons'
import React, { ReactNode, useEffect } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { AssetUploadItem } from '@/components/shared/asset-upload/asset-upload-item'
import { ButtonDiv } from '@/components/ui/button'
import { labelVariants } from '@/components/ui/label'
import {
  UploaderAsset,
  useAssetUploaderSimple,
} from '@/lib/hooks/use-asset-uploader-simple'
import { useToken } from '@/lib/hooks/use-token'
import { cn } from '@/lib/utils/cn'

type Props = {
  initialAssets?: PartialMessage<Asset>[]
  size: number
  alt?: string
  label?: ReactNode
  onUploadComplete: (assets: UploaderAsset[] | null) => void
  className?: string
  bucket: string
  hidePreview?: boolean
  accept?: Accept
  caption?: ReactNode
  maxFiles?: number
  maxFileSize?: number
}

export const PublicAssetUploadSimple = ({
  initialAssets,
  size,
  onUploadComplete,
  bucket,
  label,
  className,
  hidePreview,
  maxFiles = 5,
  maxFileSize = 10 * 1024 * 1024, // 10 mb
  accept,
  caption = 'Up to 5 files. Max 10MB size.',
}: Props) => {
  const { t } = useTranslation()
  const { callOptions } = useToken()
  const mutationDelete = useMutation(deletePublicAsset, {
    callOptions,
    onSuccess: () => {},
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const mutationCreate = useMutation(createPublicAsset, {
    callOptions,
    onSuccess: (data) => {
      data.assetIds.map((assetId) => {
        updateAssetUpload(assetId, { status: 'Completed' })
      })
    },
    onError: (error, variables) => {
      variables.assets?.map((asset) => {
        if (asset.id) {
          updateAssetUpload(asset.id, { status: 'Failed' })
        }
      })
    },
  })

  const onAssetUpload = async (asset: UploaderAsset) => {
    await mutationCreate.mutateAsync({
      assets: [
        {
          id: asset.id,
          src: asset.src,
          fileName: asset.fileName,
          bucket: asset.bucket,
          height: asset.height,
          width: asset.width,
          size: asset.size,
        },
      ],
    })
  }
  const onAssetDelete = async (asset: UploaderAsset) => {
    await mutationDelete.mutateAsync({
      assetId: asset.id,
    })
  }

  const {
    selectedAssets,
    uploadFiles,
    removeAssetUpload,
    isUploading,
    updateAssetUpload,
  } = useAssetUploaderSimple({
    initialAssets: ((initialAssets as Asset[]) || []).map((asset) => {
      return {
        ...asset,
        new: false,
        status: 'Completed',
      }
    }),
    bucket,
    onUploadComplete,
    onUploadAsset: onAssetUpload,
    onDeleteAsset: onAssetDelete,
  })

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles,
    maxSize: maxFileSize,
    accept,
    onError: (err) => {
      toast.error(err.message)
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map((rejection) => {
        const error = rejection.errors
          .map((error) => {
            return error.message
          })
          .join('. ')

        toast.error(error)
      })
    },
  })

  useEffect(() => {
    if (acceptedFiles.length === 0) {
      return
    }

    // Make sure to only accept files based on maxFile limit
    const trimFiles = acceptedFiles.slice(0, maxFiles - selectedAssets.length)

    const processFiles = async () => {
      await uploadFiles(trimFiles)
    }

    if (trimFiles.length > 0) {
      processFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  return (
    <>
      {label && (
        <div className="flex justify-between">
          <div>
            <span className={cn(labelVariants(), 'mb-2 block')}>{label}</span>
          </div>
        </div>
      )}

      {(selectedAssets || []).map((assetUpload) => (
        <AssetUploadItem
          key={assetUpload.id}
          size={size}
          className={className}
          hidePreview={hidePreview}
          asset={assetUpload}
          removeAssetUpload={(assetUpload) => {
            removeAssetUpload(assetUpload.id)
          }}
        />
      ))}

      {/* Upload */}
      {!isUploading && selectedAssets.length < maxFiles && (
        <div className={cn('relative mb-4 flex flex-col', className)}>
          <div className="flex items-center">
            <div
              className={cn(
                'overflow-hidden rounded-2xl border border-border shrink-0',
                className,
              )}
              style={{ height: size, width: size }}
              onClick={open}
            >
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div
                  className="flex items-center justify-center bg-card text-card-foreground"
                  style={{ height: size, width: size }}
                >
                  <ArrowOutOfBoxIcon />
                </div>
              </div>
            </div>

            <div className="ml-4 flex flex-col space-y-2">
              <div onClick={open} className="inline-flex cursor-pointer">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <ButtonDiv size="sm">
                    {selectedAssets.length ? t('add_more') : t('browse')}
                  </ButtonDiv>
                </div>
              </div>

              {caption && (
                <div className="text-sm text-muted-foreground">{caption}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
