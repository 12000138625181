import { Input } from '@/components/ui/input'

import { FormFieldProps } from './dynamic-input'

export const InputEmail = ({ field, form, inputClassName }: FormFieldProps) => {
  const content = field?.content

  return (
    <Input
      {...form.register(`${field?.id}`, {
        required: content?.required ? 'Field is required' : false,
        pattern:
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      })}
      type="email"
      placeholder="E.g. you@email.com"
      autoComplete="email"
      className={inputClassName}
    />
  )
}
