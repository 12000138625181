import type { PartialMessage } from '@bufbuild/protobuf'
import { FormFieldType } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/form_field_type_pb'
import type { FormField } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/form_field_pb'
import { UseFormReturn } from 'react-hook-form'

import { Label } from '@/components/ui/label'
import { Validation } from '@/components/ui/validation'
import { cn } from '@/lib/utils/cn'

import { InputCheckbox } from './input-checkbox'
import { InputDate } from './input-date'
import { InputEmail } from './input-email'
import { InputFile } from './input-file'
import { InputName } from './input-name'
import { InputNumber } from './input-number'
import { InputPhone } from './input-phone'
import { InputRadio } from './input-radio'
import { InputSelect } from './input-select'
import { InputSignature } from './input-signature'
import { InputText } from './input-text'
import { InputTextarea } from './input-textarea'
import { InputTime } from './input-time'

export type FormFieldProps = {
  field: PartialMessage<FormField>
  form: UseFormReturn<any, any>
  inputClassName?: string
}

const GenerateInput = ({
  kind,
  ...props
}: {
  kind?: FormFieldType | string | undefined
} & FormFieldProps) => {
  switch (kind) {
    case FormFieldType.CHECKBOX:
    case 'CHECKBOX':
    case 'FORM_FIELD_TYPE_CHECKBOX':
      return <InputCheckbox {...props} />
    case FormFieldType.DATE:
    case 'DATE':
    case 'FORM_FIELD_TYPE_DATE':
      return <InputDate {...props} />
    case FormFieldType.EMAIL:
    case 'EMAIL':
    case 'FORM_FIELD_TYPE_EMAIL':
      return <InputEmail {...props} />
    case FormFieldType.NUMBER:
    case 'NUMBER':
    case 'FORM_FIELD_TYPE_NUMBER':
      return <InputNumber {...props} />
    case FormFieldType.PHONE:
    case 'PHONE':
    case 'FORM_FIELD_TYPE_PHONE':
      return <InputPhone {...props} />
    case FormFieldType.RADIO:
    case 'RADIO':
    case 'FORM_FIELD_TYPE_RADIO':
      return <InputRadio {...props} />
    case FormFieldType.SELECT:
    case 'SELECT':
    case 'FORM_FIELD_TYPE_SELECT':
      return <InputSelect {...props} />
    case FormFieldType.TEXT:
    case 'TEXT':
    case 'FORM_FIELD_TYPE_TEXT':
      return <InputText {...props} />
    case FormFieldType.TEXTAREA:
    case 'TEXTAREA':
    case 'FORM_FIELD_TYPE_TEXTAREA':
      return <InputTextarea {...props} />
    case FormFieldType.TIME:
    case 'TIME':
    case 'FORM_FIELD_TYPE_TIME':
      return <InputTime {...props} />
    case FormFieldType.FILE:
    case 'FILE':
    case 'FORM_FIELD_TYPE_FILE':
      return <InputFile {...props} />
    case FormFieldType.SIGNATURE:
    case 'SIGNATURE':
    case 'FORM_FIELD_TYPE_SIGNATURE':
      return <InputSignature {...props} />
    case FormFieldType.NAME:
    case 'NAME':
    case 'FORM_FIELD_TYPE_NAME':
      return <InputName {...props} />
    case FormFieldType.HEADING:
    case 'HEADING':
    case 'FORM_FIELD_TYPE_HEADING':
    default:
      return null
  }
}

type Props = {
  field: PartialMessage<FormField>
  form: any
  className?: string
  labelClassName?: string
  instructionClassName?: string
  inputClassName?: string
}

export const DynamicInput = ({
  field,
  form,
  className,
  labelClassName,
  instructionClassName,
  inputClassName,
}: Props) => {
  const content = field?.content

  return (
    <div className={cn('mb-4', className)}>
      {content?.label && (
        <Label htmlFor={`${field.id}`} className={cn('mb-2', labelClassName)}>
          {content?.label}
          {content?.required ? '*' : ''}
        </Label>
      )}

      {content?.instruction && (
        <div
          className={cn(
            'mt-1 text-sm text-muted-foreground',
            instructionClassName,
          )}
        >
          {content.instruction}
        </div>
      )}

      <div className="mt-2">
        <GenerateInput
          kind={content?.kind}
          field={field}
          form={form}
          inputClassName={inputClassName}
        />
      </div>

      {form?.formState?.errors && form?.formState?.errors?.[`${field.id}`] && (
        <Validation variant="error" className={instructionClassName}>
          {form?.formState?.errors?.[`${field.id}`]?.message ||
            'Field has an error'}
        </Validation>
      )}
    </div>
  )
}
