import React from 'react'
import { Controller } from 'react-hook-form'

import { PhoneInput, PhoneInputProps } from '@/components/ui/phone-input'

import { FormFieldProps } from './dynamic-input'

export const InputPhone = ({
  field,
  form,
  inputClassName,
  ...rest
}: Pick<PhoneInputProps, 'defaultCountry'> & FormFieldProps) => {
  const content = field?.content

  return (
    <Controller
      name={`${field?.id}`}
      control={form.control}
      rules={{ required: content?.required ? 'Field is required' : false }}
      render={({ field: { onChange, value } }) => (
        <PhoneInput
          value={value}
          onChange={onChange}
          className={inputClassName}
          {...rest}
        />
      )}
    />
  )
}
