import React, { useEffect, useRef } from 'react'
import SignaturePad from 'signature_pad'

import { Button } from '@/components/ui/button'

import { FormFieldProps } from './dynamic-input'

export const InputSignature = ({ field, form }: FormFieldProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const signaturePadRef = useRef<SignaturePad | null>(null)

  const ASPECT_RATIO = 150 / 60

  const setupCanvas = () => {
    if (!canvasRef.current) return

    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    const width = canvasRef.current.offsetWidth
    const height = width / ASPECT_RATIO

    canvasRef.current.width = width * ratio
    canvasRef.current.height = height * ratio
    canvasRef.current.getContext('2d')?.scale(ratio, ratio)

    // If there's an existing signature pad, clear it
    signaturePadRef.current?.clear()
  }

  useEffect(() => {
    if (!canvasRef.current) return

    setupCanvas()

    // Initialize SignaturePad
    signaturePadRef.current = new SignaturePad(canvasRef.current, {
      backgroundColor: 'rgb(255, 255, 255)',
      penColor: 'rgb(0, 0, 0)',
    })

    // Update form value when signature changes
    signaturePadRef.current.addEventListener('endStroke', () => {
      if (signaturePadRef.current) {
        const dataUrl = signaturePadRef.current.toDataURL()
        form.setValue(field?.id!, dataUrl, { shouldValidate: true })
      }
    })

    return () => {
      signaturePadRef.current?.off()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.id, form])

  const handleClear = () => {
    signaturePadRef.current?.clear()
    form.setValue(field?.id!, '', { shouldValidate: true })
  }

  useEffect(() => {
    const handleResize = () => {
      if (!canvasRef.current || !signaturePadRef.current) return
      setupCanvas()
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mt-2">
      <div className="rounded-md border p-2">
        <canvas
          ref={canvasRef}
          className="w-full touch-none"
          style={{
            height: 'auto',
            aspectRatio: `${ASPECT_RATIO}`,
          }}
        />
      </div>
      <Button
        type="button"
        onClick={handleClear}
        className="mt-2"
        size="xs"
        variant="secondary"
      >
        Clear signature
      </Button>
    </div>
  )
}
