import { FormFieldProps } from './dynamic-input'

export const InputRadio = ({ field, form }: FormFieldProps) => {
  const content = field?.content

  return (
    <>
      {(content?.options || []).map((row) => (
        <label key={row.id} className="mb-2 block">
          <input
            {...form.register(`${field?.id}`, {
              required: content?.required ? 'Field is required' : false,
            })}
            type="radio"
            value={row.label}
            required
            className="mr-2 leading-tight"
          />
          {row.label}
        </label>
      ))}
    </>
  )
}
