import { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'

import { getSupabaseClient } from '@/lib/supabase/supabase-client'

type AssetUploadStatus = 'Initial' | 'Uploading' | 'Completed' | 'Failed'
export type UploaderAsset = {
  id: string
  bucket: string
  src: string
  fileName: string
  file?: File
  new?: boolean
  width?: number
  height?: number
  status: AssetUploadStatus
  error?: string
  size?: number
}

type Props = {
  initialAssets?: UploaderAsset[]
  onUploadAsset: (asset: UploaderAsset) => Promise<void>
  onDeleteAsset: (asset: UploaderAsset) => Promise<void>
  // Fires when all assets have been uploaded
  onUploadComplete?: (asset: UploaderAsset[]) => void
  bucket: string
}

export const useAssetUploaderSimple = ({
  initialAssets,
  bucket,
  onUploadAsset,
  onDeleteAsset,
  onUploadComplete,
}: Props) => {
  const supabase = getSupabaseClient()
  const [selectedAssets, setSelectedAssets] = useState<UploaderAsset[]>(
    initialAssets || [],
  )
  const isInitial = useMemo(() => {
    const selectedIds = selectedAssets.map((asset) => asset.id)
    const initialIds = (initialAssets || []).map((asset) => asset.id)
    // check ids in both arrays
    return selectedIds.every((id) => initialIds.includes(id))
  }, [selectedAssets, initialAssets])

  const isUploading = useMemo(() => {
    return selectedAssets.some(
      (asset) => asset.status === 'Uploading' || asset.status === 'Initial',
    )
  }, [selectedAssets])
  const isUploadingComplete = useMemo(() => {
    return selectedAssets.every(
      (asset) => asset.status === 'Completed' || asset.status === 'Failed',
    )
  }, [selectedAssets])
  useEffect(() => {
    if (isUploadingComplete && !isInitial) {
      onUploadComplete?.(selectedAssets)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadingComplete, selectedAssets])

  const uploadFiles = useCallback(
    async (files: File[]) => {
      for (const file of files) {
        const id = v4()
        const fileExt = file.name.split('.').pop() || ''
        const fileName = `${id}.${fileExt}`
        const filePath = `${fileName}`
        try {
          const image = new Image()
          await new Promise((resolve, reject) => {
            image.addEventListener('load', () => {
              setSelectedAssets((prev) => [
                ...prev,
                {
                  id: id,
                  src: filePath,
                  bucket,
                  file: file,
                  width: image.width,
                  height: image.height,
                  fileName: file.name,
                  status: 'Initial',
                  new: true,
                  size: file.size,
                },
              ])
              resolve(null)
            })
            image.addEventListener('error', (errorEvent) => {
              reject(new Error(`Could not load image: ${errorEvent}`))
            })
            image.src = URL.createObjectURL(file)
          })
        } catch (_error) {
          // If error occurs, it means we were unable to load it as image
          setSelectedAssets((prev) => [
            ...prev,
            {
              id,
              src: filePath,
              file: file,
              bucket,
              fileName: file.name,
              status: 'Initial',
              new: true,
              size: file.size,
            },
          ])
        }
      }
    },
    [setSelectedAssets, bucket],
  )
  const updateAssetUpload = useCallback(
    (id: string, data: Partial<UploaderAsset>) => {
      setSelectedAssets((prev) =>
        prev.map((asset) => (asset.id === id ? { ...asset, ...data } : asset)),
      )
    },
    [],
  )
  const removeAssetUpload = useCallback(
    async (id: string) => {
      const asset = selectedAssets.find((asset) => asset.id === id)
      if (asset) {
        await onDeleteAsset(asset)
        setSelectedAssets((prev) => prev.filter((asset) => asset.id !== id))
      }
    },
    [selectedAssets, onDeleteAsset, setSelectedAssets],
  )
  const startUpload = useCallback(
    async (asset: UploaderAsset) => {
      if (!asset.file) return
      updateAssetUpload(asset.id, { status: 'Uploading' })

      // Actually upload it to storage
      let { error: uploadError } = await supabase.storage
        .from(bucket)
        .upload(asset.src, asset.file, { upsert: true })

      if (uploadError) {
        updateAssetUpload(asset.id, { status: 'Failed' })
      } else {
        await onUploadAsset(asset)
      }
    },
    [updateAssetUpload, supabase.storage, bucket, onUploadAsset],
  )

  useEffect(() => {
    const newUploads = selectedAssets.filter(
      (asset) => asset.status === 'Initial',
    )
    if (newUploads.length > 0) {
      newUploads.forEach(startUpload)
    }
  }, [selectedAssets, startUpload])
  const updateAllUploads = useCallback(
    (change: Partial<UploaderAsset>) => {
      setSelectedAssets((prev) =>
        prev.map((asset) => ({ ...asset, ...change })),
      )
    },
    [setSelectedAssets],
  )
  return {
    uploadFiles,
    updateAssetUpload,
    removeAssetUpload,
    updateAllUploads,
    selectedAssets,
    setSelectedAssets,
    isUploading,
    isUploadingComplete,
  }
}
